import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../../components/layout/Layout';
import SideBar from '../../components/layout/SideBar';
import { getPageLink } from '../../constants';
import './SendFeedbackPage.scss';
import { PageContentType } from '../../context/PageContentType';

/**
 *
 * @param props
 * @constructor
 */
const SendFeedbackPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.help'),
    },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.sendFeedback'),
      path: getPageLink(props.pageContext.lang, 'SEND_FEEDBACK'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'SEND_FEEDBACK')}
      pageTitle={t('sendFeedbackPage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />
          <iframe
            width="640px"
            height="1900px"
            src={
              process.env.GATSBY_FEEDBACK_FORM +
              '&embed=true&lang=' +
              (props.pageContext.lang === 'fr' ? 'fr-CA' : 'en-US')
            }
            frameBorder="0"
            marginWidth={0}
            marginHeight={0}
            className="mdmp__feedback-form"
            allowFullScreen={true}
          ></iframe>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default SendFeedbackPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
